import React, { createContext, useState, useEffect } from "react";
import abi from "../output/abi.json";
import toast from 'react-hot-toast';
import { ethers } from "ethers";

const CONTRACT_ADDRESS = "0xC70C411cFDbE542e8208AF52092CA4f56B633977";
const CORRECT_NET_ID = 1;

export const DAppContext = createContext(null);

export const DAppProvider = ({ children }) => {
  const [instance, setInstance] = useState(null);
  const [userData, setUserData] = useState(null);
  const [transactionHash, setTransactionHash] = useState("");
  const [loading, setLoading] = useState(false);
  const [contractDetails, setContractDetails] = useState(null);

  const connectToContract = (provider, signer) => {
    try {
      const instance = new ethers.Contract(CONTRACT_ADDRESS, abi, provider);
      const contractWithSigner = instance.connect(signer);
      setInstance(contractWithSigner);
    } catch (error) {
      console.log(error, "Error");
    }
  }

  const connectBrowserWallet = async () => {
    try {
      setLoading(true);
      const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
      await web3Provider.send("eth_requestAccounts", []);

      const signer = web3Provider.getSigner();
      const accounts = await signer.getAddress();
      const { chainId } = await web3Provider.getNetwork();
  
      connectToContract(web3Provider, signer)
  
      if (parseInt(chainId) !== CORRECT_NET_ID)
        return alert("Please change to MainNet");
  
      setUserData({
        account: accounts,
        chainId: Number(chainId),
      });
      setLoading(true);
    } catch (error) {
      console.log(error, "Error");
      setLoading(false);
    }
    
  };

  const weiToEther = (wei) => {
    return wei;
  };

  const mint = async (count) => {
    try {
      setLoading(true);
      const instances = instance;
      const account = userData.account;
      if (!instances) return toast.error(`No instance`);
      if (!account)
        return toast.error(`No account selected. Try reauthenticating`);
      if (!count) return toast.error(`No token count provided.`);

      const {
        mint,
        mintPrice,
        balanceOf,
        totalSupply = () => {},
      } = instances;
      const publicETHPrice = await mintPrice();
      const totalSupplier = await totalSupply();
      const balancer = await balanceOf(account);
      let cost = 0;
      
      if(totalSupplier < 2222 && parseInt(balancer, 16) >= 2) {
        cost = window.BigInt(`${count * publicETHPrice}`);
      }

      if(totalSupplier < 2222 && parseInt(balancer, 16) < 2) {
        if (count > 2) {
          cost = window.BigInt(`${(count - 2) * publicETHPrice}`);
        }
      }

      if(totalSupplier > 2222) {
        cost = window.BigInt(`${count * publicETHPrice}`);
      }

      console.log('price', cost);
      
      const options = { value: cost };

      const outPut = await mint(count, options);
      const alreadyMinted = await totalSupply();
      
      setTransactionHash(outPut.hash)
      setContractDetails({
        ...contractDetails,
        alreadyMinted
      });

      setLoading(true);
      return outPut;
    } catch (error) {
      toast.error("You have to mint more than one right now, devil", {
        style: {
          border: '1px solid #713200',
          padding: '16px',
          color: '#713200',
        },
        iconTheme: {
          primary: '#713200',
          secondary: '#FFFAEE',
        },
      });
      setLoading(false);
    }
  };

  const getContractDetails = async () => {
    if (!instance) return null;
    let details = {};

    const {
      mintPrice,
      totalSupply = () => {},
    } = instance;

    const publicETHPrice = weiToEther(`${await mintPrice()}`);

    const alreadyMinted = await totalSupply();
    details.price = publicETHPrice;


    details = {
      ...details,
      alreadyMinted,
      methods: instance,
    };

    setContractDetails(details);
  };

  const resetTransactionHash = () => {
    setTransactionHash("");
  };

  useEffect(() => {
    getContractDetails();
  }, [instance]);

  return (
    <DAppContext.Provider
      value={{
        connectBrowserWallet,
        mint,
        loading,
        transactionHash,
        resetTransactionHash,
        contractDetails,
        userData,
      }}
    >
      {children}
    </DAppContext.Provider>
  );
};
