import { useState, useContext, useCallback, useRef } from "react";
import { DAppContext } from "./context";
import { Toaster } from "react-hot-toast";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeXmark, faVolumeHigh } from '@fortawesome/free-solid-svg-icons'

import Opensea from "./opensea.png";
import Twitter from "./twitter.png";
import Etherscan from "./etherscan.png";
import DevilAudio from "./devil.wav";
import Paper from './paper_fire.gif';
import CCO from './CCO.png';

function App() {
  const [count, setCount] = useState(1);
  const [paused, setPaused] = useState(true);
  const audioRef = useRef(null);
  const [ isConnected, setConnect ] = useState(false);
  const {
    connectBrowserWallet,
    mint,
    userData,
  } = useContext(DAppContext);

  const handleCounter = (e) => {
    const { dataset } = e.currentTarget;
    const { pos } = dataset;

    if (pos === "plus" && count < 5) {
      setCount(() => count + 1);
      return;
    }

    if (count - 1 !== 0) {
      setCount(() => count - 1);
    }
  };

  const handleCustomMint = useCallback(() => {
    mint(count);
  }, [count, userData]);

  const connectWallet = () => {
    setConnect(true);
    audioRef.current.play();
    setPaused(false);
    connectBrowserWallet();
  };

  const disconnectWallet = () => {
    setPaused(true);
    audioRef.current.pause();
    setConnect(false);
  };

  const handleVolumeClick = (e) => {
    if(audioRef.current.paused) {
      audioRef.current.play();
      setPaused(false);
      return;
    }
    setPaused(true);
    audioRef.current.pause();
  }

  return (
    <>
    <audio ref={audioRef} playsInline loop>
        <source src={DevilAudio} type="audio/wav" />
      </audio>
      <section className="greed-container">
        <div className="greed-wrapper">
          <button onClick={handleVolumeClick} type="button" className="greed-link-item volume">
              <FontAwesomeIcon icon={paused ? faVolumeXmark : faVolumeHigh}/>
          </button>
          <a
            href="https://etherscan.io/address/0xC70C411cFDbE542e8208AF52092CA4f56B633977"
            target="_blank"
            rel="noreferrer"
            className="greed-link-item"
          >
            <img src={Etherscan} alt="" />
          </a>
          <a
            href="https://opensea.io/collection/devilvalley"
            target="_blank"
            rel="noreferrer"
            className="greed-link-item"
          >
            <img src={Opensea} alt="" />
          </a>
          <a
            href="https://twitter.com/devilvalleywtf"
            target="_blank"
            rel="noreferrer"
            className="greed-link-item greed-twitter"
          >
            <img src={Twitter} alt="" />
          </a>
        </div>
        <div className="greed-form">
          {!!userData && isConnected ? (
            <>
              {/* <div className="greed-form-counter">
                <button
                  onClick={handleCounter}
                  type="button"
                  className="greed-btn"
                  data-pos="minus"
                />
                <span> {count} </span>
                <button
                  onClick={handleCounter}
                  type="button"
                  className="greed-btn"
                  data-pos="plus"
                />
              </div> */}
              {/* <button
                onClick={handleCustomMint}
                type="button"
                className="claim-btn"
              /> */}
              {/* <button
                type="button"
                className="disconnect-wallet"
                onClick={disconnectWallet}
              /> */}
              {/* <button
                type="button"
                className="max-mint"
              /> */}
            </>
          ) : (
            <button
              type="button"
              className="connect-wallet"
              onClick={connectWallet}
            />
          )}
        </div>
        <div className="paper-mobile">
            <img src={Paper} alt="paper"/>
        </div>
        <section className="greed-bottom">
          <div className="cco">
            <img src={CCO} alt="cco"/>
          </div>
        </section>
      </section>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}

export default App;
